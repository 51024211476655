/// <reference types="@types/workbox-sw" />

import { IPushApiClientSettings } from "../ClientSrc/Services/PushNotifications/IPushApiClientSettings";
import { PushApiClient } from "../ClientSrc/Services/PushNotifications/PushApiClient";
import { PushMessageHandler } from "./PushMessageHandler";

declare const self: ServiceWorkerGlobalScope;

declare global {
    interface ServiceWorkerGlobalScope {
        ApiConfig: () => IPushApiClientSettings;
    }
}

importScripts(
    "https://storage.googleapis.com/workbox-cdn/releases/3.6.1/workbox-sw.js",
    self.location.origin + "/js/PushApiConfig.js"
);
workbox.setConfig({ debug: false });

const cachesToKeep = ["tix-images"];

workbox.routing.registerRoute(
    new RegExp(/^(?!.*hello\.gif$).*\.(gif|png|svg|tiff)$/),
    new workbox.strategies.StaleWhileRevalidate({ cacheName: "tix-images" })
);

self.addEventListener("install", function (event) {
    event.waitUntil(self.skipWaiting());
});

self.addEventListener("activate", function (event) {
    event.waitUntil(
        caches.keys().then(function (cacheNames) {
            cacheNames.filter(function (cacheName) {
                return !cachesToKeep.includes(cacheName);
            }).map(function (cacheName) {
                return caches.delete(cacheName);
            })
        })
    );
});

if ('Notification' in self && 'PushManager' in self) {
    self.addEventListener("push", function (e) {
        const pushApiClient = new PushApiClient(self.ApiConfig());
        new PushMessageHandler(self, pushApiClient).handlePushEvent(e);
    });

    self.addEventListener("notificationclick", function (e) {
        const pushApiClient = new PushApiClient(self.ApiConfig());
        new PushMessageHandler(self, pushApiClient).handleNotificationClick(e);
    });
}
