import { IPushApiClient } from "./IPushApiClient";
import { IPushSubscriber } from "./IPushSubscriber"

interface IPushSubscriberSettings {
    visitorIdentifier: string
}

export { IPushSubscriber };

export class PushSubscriber implements IPushSubscriber {

    private serviceWorkerRegistration;
    private pushApiClient: IPushApiClient;
    private settings: IPushSubscriberSettings;
    constructor(serviceWorkerRegistration, pushApiClient: IPushApiClient, settings: IPushSubscriberSettings) {
        this.serviceWorkerRegistration = serviceWorkerRegistration;
        this.pushApiClient = pushApiClient;
        this.settings = settings;
    }

    subscribe(vapidPublicKey: string): Promise<PushSubscription> {
        return this.serviceWorkerRegistration.pushManager.subscribe({ userVisibleOnly: true, applicationServerKey: this.urlBase64ToUint8Array(vapidPublicKey) });
    }

    getSubscription(): Promise<PushSubscription> {
        return this.serviceWorkerRegistration.pushManager.getSubscription();
    }

    getPermissionState(): Promise<string> {
        return this.serviceWorkerRegistration.pushManager.permissionState({ userVisibleOnly: true });
    }

    static isSupported(): boolean {
        return 'serviceWorker' in navigator && 'Notification' in self && 'PushManager' in self;
    }  

    registerSubscription(subscription): Promise<Response> {
        if (!subscription) {
            return Promise.reject();
        }
        const rawKey = subscription.getKey('p256dh');
        const key = self.btoa(String.fromCharCode.apply(null, new Uint8Array(rawKey)));
        const rawAuthSecret = subscription.getKey('auth');
        const authSecret = rawAuthSecret
            ? self.btoa(String.fromCharCode.apply(null, new Uint8Array(rawAuthSecret)))
            : '';

        const subscribeRequest = {
            auth: authSecret,
            key: key,
            endpoint: subscription.endpoint,
            visitorIdentifier: this.settings.visitorIdentifier
        }

        return this.pushApiClient.subscribe(subscribeRequest);
    }

    registerUnsubscribed(): Promise<Response> {
        const unsubscribeRequest = {
            visitorIdentifier: this.settings.visitorIdentifier
        }

        return this.pushApiClient.unsubscribe(unsubscribeRequest);
    }

    private urlBase64ToUint8Array(base64String): Uint8Array {
        const padding = '='.repeat((4 - base64String.length % 4) % 4);
        const base64 = (base64String + padding)
            .replace(/\-/g, '+')
            .replace(/_/g, '/');
        const rawData = self.atob(base64);
        const outputArray = new Uint8Array(rawData.length);
        for (var i = 0; i < rawData.length; ++i) {
            outputArray[i] = rawData.charCodeAt(i);
        }
        return outputArray;
    };
}