import { INotificationReceivedRequest } from "./INotificationReceivedRequest";
import { IPushApiClient } from "./IPushApiClient";
import { IPushApiClientSettings } from "./IPushApiClientSettings";
import { ISaveSubscriptionSettingsRequest } from "./ISaveSubscriptionSettingsRequest";
import { ISubscribeRequest } from "./ISubscribeRequest";
import { IUnsubscribeRequest } from "./IUnsubscribeRequest";

export { IPushApiClient };

export class PushApiClient implements IPushApiClient {
    pushApiEndpointUrl: string;

    constructor(settings: IPushApiClientSettings) {
        this.pushApiEndpointUrl = settings.pushApiEndpointUrl;
    }

    subscribe(subscribeRequest: ISubscribeRequest): Promise<Response> {
        return fetch(`${this.pushApiEndpointUrl}/Subscription/Subscribe`,
            {
                method: 'POST',
                mode: 'cors',
                body: JSON.stringify(subscribeRequest),
                headers: {
                    'Content-type': "application/json"
                }
            });
    }

    unsubscribe(unsubscribeRequest: IUnsubscribeRequest): Promise<Response> {
        return fetch(`${this.pushApiEndpointUrl}/Subscription/Unsubscribe`,
            {
                method: 'POST',
                mode: 'cors',
                body: JSON.stringify(unsubscribeRequest),
                headers: {
                    'Content-type': "application/json"
                }
            });
    }

    received(notificationReceivedRequest: INotificationReceivedRequest): Promise<Response> {
        return fetch(`${this.pushApiEndpointUrl}/Notification/Received`,
            {
                method: 'POST',
                mode: 'cors',
                body: JSON.stringify(notificationReceivedRequest),
                headers: {
                    'Content-type': "application/json"
                }
            });
    }

    saveSubscriptionSettings(saveSubscriptionSettingsRequest: ISaveSubscriptionSettingsRequest): Promise<Response> {
        return fetch(`${this.pushApiEndpointUrl}/SubscriptionSettings/Save`,
            {
                method: 'POST',
                mode: 'cors',
                body: JSON.stringify(saveSubscriptionSettingsRequest),
                headers: {
                    'Content-type': "application/json"
                }
            });
    }
}