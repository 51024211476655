import { IPushApiClient } from "../ClientSrc/Services/PushNotifications/IPushApiClient";
import { PushSubscriber } from "../ClientSrc/Services/PushNotifications/PushSubscriber";

export class PushMessageHandler {

    private readonly serviceWorkerScope: ServiceWorkerGlobalScope;
    private readonly pushApiClient: IPushApiClient;

    constructor(serviceWorkerScope, pushApiClient: IPushApiClient) {
        this.serviceWorkerScope = serviceWorkerScope;
        this.pushApiClient = pushApiClient;
    }

    public handlePushEvent(pushEvent: PushEvent): void {

        const pushEventReceived = async (swRegistration: ServiceWorkerRegistration) => {

            if (pushEvent.data) {
                // defined as any in \node_modules\typescript\lib\lib.webworker.d.ts
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                let d: any = {};
                try {
                    d = pushEvent.data.json();
                } catch {
                    return;
                }

                if (d.notification) {

                    const options = {
                        body: d.notification.description,
                        image: d.notification.image,
                        badge: d.notification.badge,
                        icon: d.notification.icon,
                        tag: d.notification.tag,
                        requireInteraction: d.notification.requireInteraction,
                        renotify: d.notification.tag || d.notification.tag == "" ? d.notification.renotify : false,
                        actions: [],
                        data: {
                            navigateToUrl: d.notification.landingUrl,
                            notificationSettingsUrl: d.notification.settingsUrl,
                        }
                    };

                    if (d.notification.landingAction) {
                        options.actions.push({
                            action: "NavigateTo",
                            title: d.notification.landingAction.title,
                            icon: d.notification.landingAction.icon
                        })
                    }
                    if (d.notification.settingsAction) {
                        options.actions.push({
                            action: "Settings",
                            title: d.notification.settingsAction.title,
                            icon: d.notification.settingsAction.icon
                        })
                    }

                    return swRegistration.showNotification(d.notification.title, options)
                        .then(() => this.pushApiClient.received({
                            visitorIdentifier: d.notification.visitorIdentifier,
                            notificationIdentifier: d.notification.notificationIdentifier
                        }))
                        .then(() => {
                            const subscriber = new PushSubscriber(swRegistration, this.pushApiClient, { visitorIdentifier: d.notification.visitorIdentifier });
                            return subscriber.getSubscription()
                                .then(sub => subscriber.registerSubscription(sub))
                                .catch(() => Promise.resolve());
                        })
                        .catch(() => { });
                }
            }
        };

        pushEvent.waitUntil(pushEventReceived(this.serviceWorkerScope.registration)
            .catch(() => { }));
    }

    public handleNotificationClick(notificationEvent): void {
        if (notificationEvent.action == "NavigateTo") {
            const path = notificationEvent.notification.data.navigateToUrl;
            notificationEvent.notification.close();
            this.serviceWorkerScope.clients.openWindow(path);

        } else if (notificationEvent.action == "Settings") {
            const path = notificationEvent.notification.data.notificationSettingsUrl;
            notificationEvent.notification.close();
            this.serviceWorkerScope.clients.openWindow(path);

        } else {
            //default fallback for now in case they just click the notification itself and not an action button
            const path = notificationEvent.notification.data.navigateToUrl;
            notificationEvent.notification.close();
            this.serviceWorkerScope.clients.openWindow(path);
        }
    }
}
